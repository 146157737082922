<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
      >
        <v-card
          flat
          rounded="lg"
          class="datatable-card-border"
        >
          <v-tabs
            v-model="tab"
            show-arrows
            background-color="transparent"
            color="primary"
            grow
          >
            <v-tab
              v-for="item in items"
              :key="item"
            >
              {{ $t(item) }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <ClientChangeProfile />
            </v-tab-item>

            <v-tab-item>
              <ClientChangePassword />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientChangeProfile from './ClientChangeProfile.vue'
import ClientChangePassword from './ClientChangePassword.vue'

export default {
  name: 'Profile',

  components: {
    ClientChangeProfile,
    ClientChangePassword
  },

  data() {
    return {
      tab: null,
      items: ['Change profile', 'Change password']
    }
  },

  computed: {
    ...mapGetters([
      'authUserData'
    ])
  },
}
</script>

<style lang="scss" scoped></style>
