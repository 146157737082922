<template>
  <div>
    <v-card
      flat
      class="mt-4"
    >
      <v-card-title
        primary-title
        class="justify-center"
      >
        <img
          :src="$_get_image('edit-user.png')"
          width="80"
          class="d-block"
        >
      </v-card-title>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="handleSubmit"
      >
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="authShareholder.username"
                :label="$t('Username')"
                outlined
                dense
                disabled
                :rules="usernameRules"
                autocomplete="off"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="authShareholder.first_name"
                :label="$t('Full Name')"
                outlined
                dense
                :rules="fullNameRules"
                autocomplete="off"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="authShareholder.phone"
                :label="$t('Phone')"
                outlined
                dense
                :rules="fullNameRules"
                autocomplete="off"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="my-3">
          <v-btn
            type="submit"
            style="width: 100%"
            depressed
            color="primary"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      valid: true,
      usernameRules: [v => !!v || this.$t('Username is required')],
      fullNameRules: [v => !!v || this.$t('Full Name is required')],
      phoneRules: [v => !!v || this.$t('Phone number is required')]
    }
  },

  computed: {
    ...mapGetters([
      'authShareholder'
    ])
  },

  methods: {
    ...mapActions([
      'setSnack'
    ]),

    handleSubmit() {
      if (this.$refs.form.validate()) {
        const formData = {
          first_name: this.authShareholder.first_name,
          phone: this.authShareholder.phone
        }

        axios.put('shareholder/client/update-profile/', formData).then(() => {
          this.$_notify('Updated successfully')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
